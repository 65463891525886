<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            Cancel Subscription
          </CCardHeader>
          <CCardBody>
            <template v-if="isRequestSent">
              <div>Thank you.</div>
            </template>
            <template v-else>
              <p><b>We're sad to see you go. To help us get better please specify your cancelation reason below:</b></p>
              <div style="margin-bottom: 20px;">
                <CInputRadio
                    v-for="(option, optionIndex) in shuffledCancellationReasons"
                    :key="optionIndex"
                    name="cancellation_reason"
                    :label="option"
                    :value="option"
                    @update:checked="selectReason(option)"
                />
                <CInputRadio
                    key="other"
                    name="cancellation_reason"
                    label="Other"
                    value="Other"
                    @update:checked="selectReason('Other')"
                />
                <CInput
                    style="margin-top: 5px;"
                    v-model="cancellationReasonDescription"
                    v-if="selectedReason === 'Other'"
                />
              </div>

              <CButton style="margin-right: 15px;" color="success" to="/domain">
                Back
              </CButton>
              <CButton color="danger" @click="cancelSubscription()">
                Cancel Subscription
              </CButton>
            </template>
          </CCardBody>

          <CElementCover v-if="isLoading">
            <CSpinner size="5xl" color="info"/>
          </CElementCover>

        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {Auth} from "aws-amplify";
import SubscriptionService from "@/services/SubscriptionService";

export default {
  name: 'Cancellation',
  components: {},
  data() {
    return {
      isLoading: false,
      isRequestSent: false,
      cancellationReasons: [
        "Moving to another provider",
        "Discontinuing the site",
        "Switching to another technology",
        "Too expensive",
      ],
      selectedReason: null,
      cancellationReasonDescription: '',
      networkId: ''
    }
  },
  methods: {
    async cancelSubscription() {

      if(!this.selectedReason) {
        this.$toast.error("Choose a reason please.");
        return;
      }

      if(this.selectedReason === 'Other' && !this.cancellationReasonDescription) {
        this.$toast.error("Reason description is required.");
        return;
      }

      if(!confirm('Are you sure?')) return;

      this.isLoading = true;

      try {
        const reason = this.selectedReason === 'Other'
                        ? this.cancellationReasonDescription
                        : this.selectedReason;

        const response = await SubscriptionService.cancel(this.networkId, reason);
        console.log(response.data.message);

        this.isRequestSent = true;
        // this.$toast.success(response.data.message);

      } finally {
        this.isLoading = false;
      }
    },
    selectReason(reason) {
      this.selectedReason = reason;
    },
    shuffleArray (array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1))
        let temp = array[i]
        array[i] = array[j]
        array[j] = temp
      }
      return array
    },
  },
  computed: {
    shuffledCancellationReasons() {
      return this.shuffleArray(this.cancellationReasons);
    },
  },
  async mounted() {

    const userSessionData = await Auth.currentAuthenticatedUser();
    const userGroups = userSessionData.signInUserSession.idToken.payload['cognito:groups'] || [];

    if(userGroups.includes('admin')) {
      if(this.$route.query.network_id) {

        this.networkId = this.$route.query.network_id;
      } else {
        this.$toast.error('Wrong network_id')
        return false;
      }
    }
  }
}
</script>
<style scoped lang="scss">
.form-check {
  margin-bottom: 5px;
}
</style>